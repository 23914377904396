import React, { useCallback } from 'react';
import { ResourceItem } from 'components/resources-list-item';
import { useSelector, useDispatch } from 'react-redux';
import { resourcesState } from 'store/resources/selectors';
import { RESOURCE_PATH } from 'shared/consts';
import { push } from 'store/router/actions';
import { useLocation } from 'react-router-dom';
import { resetResource } from 'store/resource/actions';
import {
  selectAppInstanceConfigs,
  selectHasVerticalAdapterNotNativeOrEssensys,
} from 'store/app-instance-configs/selectors';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { updateCurrentAppInstanceConfigUuid } from 'store/app-instance-configs/actions';
import { useSearchParams } from 'hooks/use-search-params.hook';
import { searchParams } from 'shared/types/search-params';
import { SectionedOffContent } from 'pages/resources/content/flex-content/SectionedOffContent';

export const FlexContent = (): JSX.Element => {
  const { resourceBookingPortfolioBased } = useFlags();
  const { resources } = useSelector(resourcesState);
  const dispatch = useDispatch();
  const { search } = useLocation();
  const appInstanceConfigs = useSelector(selectAppInstanceConfigs);
  const { locationId } = useSearchParams<searchParams>();
  const verticalAdapterNotNativeOrEssensys = useSelector(selectHasVerticalAdapterNotNativeOrEssensys);

  const onResourceClick = useCallback(
    (resourceUuid: string, locationUuid: string) => {
      const currentLocationId = verticalAdapterNotNativeOrEssensys ? locationId : locationUuid;
      const resourceAppInstanceConfigUuid = appInstanceConfigs?.find(
        config => config.location_id === currentLocationId,
      )?.uuid;

      dispatch(updateCurrentAppInstanceConfigUuid({ currentAppInstanceConfigUuid: resourceAppInstanceConfigUuid }));
      dispatch(resetResource());
      dispatch(push(`${RESOURCE_PATH}/${resourceUuid}${search}`));
    },
    [appInstanceConfigs, dispatch, locationId, search, verticalAdapterNotNativeOrEssensys],
  );

  if (resourceBookingPortfolioBased) {
    return <SectionedOffContent resources={resources} onResourceClick={onResourceClick} />;
  }

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {resources?.map(resource => (
        <ResourceItem key={resource.uuid} resource={resource} onClick={onResourceClick} />
      ))}
    </>
  );
};
