import { PaymentState } from './types';
import { RootState } from 'store/reducer';
import { createSelector } from 'reselect';
import { APPLE_PAY } from 'shared/consts/payment-method-types';

export const paymentSelector = (state: RootState): PaymentState => state.payment;
const launchDarklyFlagSelector = (state: RootState) => state.featureFlags;

export const selectPaymentMethods = createSelector(
  [paymentSelector, launchDarklyFlagSelector],
  (payment, launchDarklyFlags) => {
    const isSupportApplePay =
      navigator.userAgent.includes('iPhone') && launchDarklyFlags.flags.enableHqoMiniappApplePay;

    return isSupportApplePay
      ? payment.paymentMethods
      : payment.paymentMethods?.filter(paymentMethod => paymentMethod.payment_method_type !== APPLE_PAY);
  },
);

export const selectGetPaymentMethodsStatus = createSelector(
  [paymentSelector],
  payment => payment.getPaymentsMethods.status,
);

export const selectGetPaymentMethodsError = createSelector(
  [paymentSelector],
  payment => payment.getPaymentsMethods.error,
);

export const selectDeletePaymentMethodsStatus = createSelector(
  [paymentSelector],
  payment => payment.deletePaymentMethod.status,
);

export const selectDeletePaymentMethodsError = createSelector(
  [paymentSelector],
  payment => payment.deletePaymentMethod.error,
);

export const selectSavePaymentMethodStatus = createSelector(
  [paymentSelector],
  payment => payment.savePaymentMethod.status,
);

export const selectSavePaymentMethodError = createSelector(
  [paymentSelector],
  payment => payment.savePaymentMethod.error,
);

export const selectCurrentPaymentMethodId = createSelector(
  [paymentSelector],
  payment => payment.currentPaymentMethodId,
);

export const selectUpdatePaymentMethodStatus = createSelector(
  [paymentSelector],
  payment => payment.updatePaymentMethod.status,
);
