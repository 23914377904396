import { useCallback, useEffect, useMemo } from 'react';
import mapboxgl, { Marker } from 'mapbox-gl';
import { BOTTOM_PADDING, DEFAULT_MARKER_OPTIONS, MAX_ZOOM, SIDE_PADDING, TOP_PADDING } from '../constants';
import { useDispatch, useSelector } from 'react-redux';
import { selectGetResourcesLoadingStatus } from 'store/resources/selectors';
import { INITIAL_POSITION_SWIPE_MODAL_FOR_MAP_VIEW } from 'pages/resources/components/resources-swipe-modal';
import {
  selectAppInstanceConfigsLocations,
  selectAppInstanceConfigs,
  selectCurrentBuildingLocationCoordinates,
  selectCurrentLocationId,
} from 'store/app-instance-configs/selectors';
import { useIsSmallViewportWidth } from '@hqo/react-components-library/dist/viewport';
import { setSelectedAppInstanceConfigsLocations, updateCurrentLocationId } from 'store/app-instance-configs/actions';
import { useLocation } from 'react-router-dom';
import { AppInstanceConfigWithLocations } from 'store/app-instance-configs/types';

interface UseMapView {
  mapRef: React.MutableRefObject<mapboxgl.Map | null>;
}

export const useMapView = ({ mapRef }: UseMapView) => {
  const isMobileDevice = useIsSmallViewportWidth();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const appInstanceConfigs = useSelector(selectAppInstanceConfigs);
  const appInstanceConfigsLocations = useSelector(selectAppInstanceConfigsLocations);
  const currentLocationId = useSelector(selectCurrentLocationId);
  const currentBuildingLocationCoordinates = useSelector(selectCurrentBuildingLocationCoordinates);
  const isResourcesLoading = useSelector(selectGetResourcesLoadingStatus);
  const { longitude: defaultLongitude, latitude: defaultLatitude } = currentBuildingLocationCoordinates;
  const mapBottomPadding = useMemo<number>(
    () => (isMobileDevice ? INITIAL_POSITION_SWIPE_MODAL_FOR_MAP_VIEW : BOTTOM_PADDING),
    [isMobileDevice],
  );

  const locationsFromPath = useMemo<Array<string>>(() => {
    const query = new URLSearchParams(search);

    return query.get('locationId')?.split(',') ?? [];
  }, [search]);

  const selectedLocationCoordinates = useMemo(
    () =>
      appInstanceConfigs
        .filter(config => locationsFromPath.includes(config.location_id))
        .map(config => config.location_coordinates),
    [appInstanceConfigs, locationsFromPath],
  );

  const geojson = useMemo(() => {
    return {
      type: 'FeatureCollection',
      features: appInstanceConfigsLocations.map(location => ({
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [location?.longitude ?? defaultLongitude, location?.latitude ?? defaultLatitude] as [
            number,
            number,
          ],
          locationId: location?.locationId,
        },
      })),
    };
  }, [defaultLatitude, defaultLongitude, appInstanceConfigsLocations]);

  useEffect(() => {
    if (!isResourcesLoading) {
      const bounds = new mapboxgl.LngLatBounds();
      for (const location of selectedLocationCoordinates) {
        bounds.extend([location?.longitude ?? defaultLongitude, location?.latitude ?? defaultLatitude]);
      }

      mapRef.current.fitBounds(bounds, {
        padding: { left: SIDE_PADDING, right: SIDE_PADDING, bottom: mapBottomPadding, top: TOP_PADDING },
        maxZoom: MAX_ZOOM,
      });
    }
  }, [
    defaultLatitude,
    defaultLongitude,
    geojson,
    isResourcesLoading,
    mapBottomPadding,
    mapRef,
    appInstanceConfigsLocations,
    selectedLocationCoordinates,
  ]);

  const formatSelectedConfigsWithLocations = useCallback(
    (locationIds: Array<string>) => {
      return Object.values(
        appInstanceConfigs.reduce<Record<string, AppInstanceConfigWithLocations>>(
          (configsWithLocations, { uuid, location_id }) => {
            const updatedConfigsWithLocations = { ...configsWithLocations };

            if (!locationIds.includes(location_id)) {
              return updatedConfigsWithLocations;
            }

            if (!updatedConfigsWithLocations[uuid]) {
              updatedConfigsWithLocations[uuid] = { uuid, locationIds: [] };
            }
            updatedConfigsWithLocations[uuid] = {
              ...updatedConfigsWithLocations[uuid],
              locationIds: [...updatedConfigsWithLocations[uuid].locationIds, location_id],
            };

            return updatedConfigsWithLocations;
          },
          {},
        ),
      );
    },
    [appInstanceConfigs],
  );

  const orderSelectedLocations = useCallback(
    (selectedLocationId: string) => {
      if (locationsFromPath.includes(selectedLocationId)) {
        const filteredLocations = locationsFromPath.filter(locationId => locationId !== selectedLocationId);
        dispatch(updateCurrentLocationId({ currentLocationId: filteredLocations.join(',') }));
        dispatch(setSelectedAppInstanceConfigsLocations(formatSelectedConfigsWithLocations(filteredLocations)));
      } else {
        const selectedLocations = [...locationsFromPath, selectedLocationId];
        dispatch(updateCurrentLocationId({ currentLocationId: selectedLocations.join(',') }));
        dispatch(setSelectedAppInstanceConfigsLocations(formatSelectedConfigsWithLocations(selectedLocations)));
      }
    },
    [dispatch, formatSelectedConfigsWithLocations, locationsFromPath],
  );

  useEffect(() => {
    const markers: Array<Marker> = [];

    geojson.features.forEach(feature => {
      const marker = new mapboxgl.Marker({
        color: currentLocationId?.split(',')?.includes(feature.geometry.locationId)
          ? DEFAULT_MARKER_OPTIONS.selectedColor
          : DEFAULT_MARKER_OPTIONS.color,
      })
        .setLngLat(feature.geometry.coordinates)
        .addTo(mapRef.current);

      markers.push(marker as unknown as Marker);

      marker.getElement().addEventListener('click', () => {
        orderSelectedLocations(feature.geometry.locationId);
      });
    });

    return () => {
      markers.forEach(marker => marker.remove());
    };
  }, [appInstanceConfigs, dispatch, geojson, mapRef]);
};
