import { Map } from '@hqo/react-components-library/dist/icons';
import { Button } from '@hqo/react-components-library/dist/atoms/button';
import styled from 'styled-components';
import { DIMENSIONS } from '@hqo/react-components-library/dist/utils';
import { EXTRA_LARGE_DIMENSION, PLUS_LARGE_DIMENSION } from 'shared/consts';

const WIDTH_OFFSET = 52;

export const Footer = styled.div<{ isMapViewEnabled?: boolean }>`
  text-align: center;
  position: fixed;
  bottom: 50px;
  width: ${({ isMapViewEnabled }) => (isMapViewEnabled ? `calc(65% - ${WIDTH_OFFSET}px)` : '100%')};

  @media only screen and (max-width: ${EXTRA_LARGE_DIMENSION}) {
    width: ${({ isMapViewEnabled }) => (isMapViewEnabled ? `calc(50% - ${WIDTH_OFFSET}px)` : '100%')};
  }

  @media only screen and (max-width: ${PLUS_LARGE_DIMENSION}) {
    width: ${({ isMapViewEnabled }) => (isMapViewEnabled ? `calc(35% - ${WIDTH_OFFSET}px)` : '100%')};
  }

  @media only screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    width: 100%;
  }
`;

export const FloorPlanButton = styled(Button)`
  margin: 0 auto;
  background-color: ${({ theme }) => theme.colors.secondary};
`;

export const FloorPlanButtonIcon = styled(Map)`
  margin-right: 4px;
`;
