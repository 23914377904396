import { AppContentWrapperStyles } from 'components/app-content-wrapper/app-content-wrapper.styles';
import { CustomFonts } from 'components/custom-fonts';
import React from 'react';

import { useGetTheme } from 'hooks/use-get-theme.hook';
import { HqoTheme, HqoThemeProvider } from '@hqo/react-components-library/dist/hqo-theme-provider';

interface AppContentWrapperProps {
  children: React.ReactNode;
}

export const AppContentWrapper = ({ children }: AppContentWrapperProps): JSX.Element => {
  const theme = useGetTheme();

  return (
    <>
      {/* @ts-ignore */}
      <AppContentWrapperStyles customThemeFonts={theme.fonts} />
      <CustomFonts />
      <HqoThemeProvider theme={theme as HqoTheme}>{children}</HqoThemeProvider>
    </>
  );
};
