import { Container, HeaderContentContainer, ContentContainer } from './styles';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { usePassedTime } from './hooks';
import { useResourcesPage } from './hooks/use-resources-page.hook';
import { useValidTypes } from './hooks/use-valid-types.hook';
import { useOnPopState } from './hooks/use-on-pop-state.hook';
import { Header } from './components/header';
import { useWebTracking } from './hooks/use-web-tracking.hook';
import { ArrowBackButton } from './components/ arrow-back-button';
import { LocationPicker } from 'pages/resources/components/location-picker';
import { FilterPicker } from './components/filter-picker';
import { TermsAndConditions } from 'components/termsAndConditions';
import { ModalContainer } from 'pages/resource/components/terms-and-conditions/components/modal-container';
import { ResourcesList } from './components/resources-list';
import { ResourcesSwipeModal } from './components/resources-swipe-modal';
import { MapViewContainer } from './components/map-view/map-view-container';
import { FilterSection } from 'hocs/filter-section';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { MobileTitle } from 'pages/resources/components/mobile-title';

export const ResourcesPage = (): JSX.Element => {
  usePassedTime();
  useValidTypes();
  useOnPopState();
  useWebTracking();
  const {
    isLoadingState,
    shouldShowMapView,
    shouldShowResourcesSwipeModal,
    isMobileDevice,
    shouldShowFloorPlanFooter,
  } = useResourcesPage();
  const { resourceBookingSkipLandingPage } = useFlags();

  return (
    <>
      <Header />
      <Container isLoading={isLoadingState}>
        <HeaderContentContainer>
          <ArrowBackButton />
          {isMobileDevice && resourceBookingSkipLandingPage && <MobileTitle />}
          <FilterSection>
            <LocationPicker />
            <FilterPicker />
          </FilterSection>
        </HeaderContentContainer>
        <ContentContainer shouldShowMapView={shouldShowMapView}>
          {!shouldShowResourcesSwipeModal && (
            <ResourcesList isLoadingState={isLoadingState} shouldShowFloorPlanFooter={shouldShowFloorPlanFooter} />
          )}
          {shouldShowMapView && <MapViewContainer />}
        </ContentContainer>
        <Routes>
          <Route path="terms-and-conditions" element={<TermsAndConditions />} />
          <Route path="quick-checkout/terms-and-conditions" element={<ModalContainer />} />
        </Routes>
        {shouldShowResourcesSwipeModal && <ResourcesSwipeModal isLoadingState={isLoadingState} />}
      </Container>
    </>
  );
};

export default ResourcesPage;
