import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export const useAvailableNowValue = (): boolean => {
  const { search } = useLocation();

  const availableNowValue = useMemo(() => {
    const query = new URLSearchParams(search);

    return query.get('availableNow') === 'true';
  }, [search]);

  return availableNowValue;
};
