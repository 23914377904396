import * as actions from './actions';

import { catchError, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';

import { AjaxResponse } from 'rxjs/ajax';
import { Epic } from 'redux-observable';
import { GetAppInstanceConfigsAPIResponse } from './types';
import { RootAction } from 'store/actions';
import { RootDependencies } from 'store/dependencies';
import { RootState } from 'store/reducer';
import { isActionOf } from 'typesafe-actions';
import { of } from 'rxjs';
import { transformErrorIntoSerializableError } from 'store/utils';
import { addUnitCodes } from 'store/unit-codes/actions';
import { ResourceBookingEnum } from 'store/app-instance-configs/enums';

export const getAppInstanceConfigsEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) =>
  action$.pipe(
    filter(isActionOf(actions.getAppInstanceConfigs.request)),
    withLatestFrom(state$),
    switchMap(([{ payload }, state]) => {
      return apiClient(state)
        .getAppInstanceConfigs(payload)
        .pipe(
          switchMap(({ response }: AjaxResponse<GetAppInstanceConfigsAPIResponse>) => {
            if (response.data.app_instance_configs.length === 0) {
              return of(
                actions.emptyAppInstanceConfigs({
                  error: { name: 'emptyAppInstanceConfigs', message: 'Empty App Instance Configs' },
                }),
                addUnitCodes(response.data.unit_code),
              );
            }

            const sortedAppInstanceConfigs = [...response.data.app_instance_configs].sort((a, b) => {
              const isNativeA = a.vertical_adapter?.name === ResourceBookingEnum.NativeAdapter;
              const isNativeB = b.vertical_adapter?.name === ResourceBookingEnum.NativeAdapter;

              if (isNativeA && !isNativeB) return -1;
              if (!isNativeA && isNativeB) return 1;

              return 0;
            });

            return of(
              actions.getAppInstanceConfigs.success({
                app_instance_configs: state.featureFlags.flags['resource-booking-sort-configs-by-integration']
                  ? sortedAppInstanceConfigs
                  : response.data.app_instance_configs,
              }),
              addUnitCodes(response.data.unit_code),
            );
          }),
          catchError((error: Error) =>
            of(actions.getAppInstanceConfigs.failure({ error: transformErrorIntoSerializableError(error) })),
          ),
        );
    }),
  );

export const onboardUserEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) =>
  action$.pipe(
    filter(isActionOf(actions.onboardUser.request)),
    withLatestFrom(state$),
    switchMap(([{ payload }, state]) => {
      return apiClient(state)
        .onboardUser(payload)
        .pipe(
          map(() => {
            return actions.onboardUser.success();
          }),
          catchError((error: Error) =>
            of(actions.onboardUser.failure({ error: transformErrorIntoSerializableError(error) })),
          ),
        );
    }),
  );
