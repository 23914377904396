import * as actions from './actions';

import { catchError, filter, mergeMap, withLatestFrom } from 'rxjs/operators';

import { AjaxResponse } from 'rxjs/ajax';
import { Epic } from 'redux-observable';
import { RootAction } from 'store/actions';
import { RootDependencies } from 'store/dependencies';
import { RootState } from 'store/reducer';
import { isActionOf } from 'typesafe-actions';
import { of } from 'rxjs';
import { ResourcesAPIResponse } from 'store/resources/types';
import { RESOURCES_MAX_CHUNK_SIZE } from 'floorplan/const';
import { getSelectedAppInstanceConfigs } from 'utils/getSelectedAppInstanceConfigs';

export const getAvailableResourcesEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) =>
  action$.pipe(
    filter(isActionOf(actions.getAvailableResources.request)),
    withLatestFrom(state$),
    mergeMap(([{ payload }, state]) => {
      const { ownerType, ownerUuid, filterParams } = payload;
      const selectedAppInstanceConfigs = getSelectedAppInstanceConfigs(state.appInstanceConfigs);

      return apiClient(state)
        .getResources(
          ownerType,
          ownerUuid,
          { ...filterParams, limit: RESOURCES_MAX_CHUNK_SIZE },
          selectedAppInstanceConfigs,
        )
        .pipe(
          mergeMap(({ response }: AjaxResponse<ResourcesAPIResponse>) => {
            const successAction = actions.getAvailableResources.success({
              resources: response.data.resources,
              offset: filterParams?.offset ?? 0,
              appInstanceConfigUuid: filterParams.appInstanceConfigUuid,
              total: response.total,
            });
            if (!filterParams?.offset && response.total > RESOURCES_MAX_CHUNK_SIZE) {
              const numberOfPages = Math.ceil(+response.total / RESOURCES_MAX_CHUNK_SIZE) - 1;
              const actionRequests = [...Array(numberOfPages).keys()].map(pageNumber => {
                return actions.getAvailableResources.request({
                  ownerType,
                  ownerUuid,
                  filterParams: {
                    ...filterParams,
                    offset: (pageNumber + 1) * RESOURCES_MAX_CHUNK_SIZE,
                  },
                });
              });
              return of(successAction, ...actionRequests);
            }
            return of(successAction);
          }),
          catchError((error: Error) =>
            of(
              actions.getAvailableResources.failure({
                error,
                offset: payload.offset,
                appInstanceConfigUuid: filterParams.appInstanceConfigUuid,
              }),
            ),
          ),
        );
    }),
  );

export const getAllResourcesEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) =>
  action$.pipe(
    filter(isActionOf(actions.getAllResources.request)),
    withLatestFrom(state$),
    mergeMap(([{ payload }, state]) => {
      const { ownerType, ownerUuid, filterParams } = payload;
      const selectedAppInstanceConfigs = getSelectedAppInstanceConfigs(state.appInstanceConfigs);

      return apiClient(state)
        .getResources(
          ownerType,
          ownerUuid,
          { ...filterParams, limit: RESOURCES_MAX_CHUNK_SIZE },
          selectedAppInstanceConfigs,
        )
        .pipe(
          mergeMap(({ response }: AjaxResponse<ResourcesAPIResponse>) => {
            const successAction = actions.getAllResources.success({
              resources: response.data.resources,
              appInstanceConfigUuid: filterParams.appInstanceConfigUuid,
              offset: filterParams?.offset ?? 0,
              total: response.total,
            });
            if (!filterParams?.offset && response.total > RESOURCES_MAX_CHUNK_SIZE) {
              const numberOfPages = Math.ceil(+response.total / RESOURCES_MAX_CHUNK_SIZE) - 1;
              const actionRequests = [...Array(numberOfPages).keys()].map(pageNumber => {
                return actions.getAllResources.request({
                  ownerType,
                  ownerUuid,
                  filterParams: {
                    ...filterParams,
                    offset: (pageNumber + 1) * RESOURCES_MAX_CHUNK_SIZE,
                  },
                });
              });
              return of(successAction, ...actionRequests);
            }
            return of(successAction);
          }),
          catchError((error: Error) =>
            of(
              actions.getAllResources.failure({
                error,
                offset: payload.offset,
                appInstanceConfigUuid: filterParams.appInstanceConfigUuid,
              }),
            ),
          ),
        );
    }),
  );
