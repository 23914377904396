import React, { useCallback, useMemo } from 'react';
import { StyledContentTile, ResponsiveResourceTile, StyledVerticalContentTile } from './styles';
import AvailableNowLabels from 'components/available-now-block/components/available-now-labels/available-now-labels';
import { useIsSmallViewportWidth } from '@hqo/react-components-library/dist/viewport';
import { useSelector } from 'react-redux';
import { selectAppInstanceConfigByLocationId } from 'store/app-instance-configs/selectors';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ResourceBookingEnum } from 'store/app-instance-configs/enums';
import { getResourceBuilding } from 'utils/getResourceBuilding';
import { Resource } from 'store/resource/types';

interface ResourceItemProps {
  resource: Resource;
  onClick?: (resourceUuid: string, locationUuid: string) => void;
}

export const ResourceItem: React.FC<ResourceItemProps> = ({ resource, onClick }: ResourceItemProps): JSX.Element => {
  const { uuid, capacity, minimum_price, image_url: imageUrl, name, location } = resource || {};
  const isMobileDevice = useIsSmallViewportWidth();
  const { resourceBookingPortfolioBased } = useFlags();
  const currentAppInstanceConfig = useSelector(selectAppInstanceConfigByLocationId(location?.id));
  const isPaidResource = Boolean(minimum_price);

  const resourceBuildingOptions = useMemo(
    () => ({
      isResourceBookingPortfolioBased: resourceBookingPortfolioBased,
      isEssensysAdapter: currentAppInstanceConfig?.vertical_adapter?.name === ResourceBookingEnum.EssensysAdapter,
    }),
    [currentAppInstanceConfig, resourceBookingPortfolioBased],
  );

  const onTileClick = useCallback(() => {
    onClick(uuid, location?.id);
  }, [onClick, uuid, location]);

  const contentTileProps = {
    className: 'resource-tile',
    title: name,
    subtitle: getResourceBuilding(resource, resourceBuildingOptions),
    imageUrl,
    onClick: onTileClick,
    statusLabels: [
      <AvailableNowLabels key="labels" isPaid={isPaidResource} capacity={Number(capacity)} title={name} />,
    ],
    skeleton: <ResponsiveResourceTile />,
  };

  return isMobileDevice ? (
    <StyledContentTile {...contentTileProps} />
  ) : (
    <StyledVerticalContentTile {...contentTileProps} />
  );
};
