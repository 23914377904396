import styled from 'styled-components';

export const AvailableNowContainer = styled.div`
  display: flex;
  align-items: center;
  height: 24px;
  margin-bottom: 32px;
  gap: 8px;
`;

export const Label = styled.div`
  font: 400 16px/16px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.fontColor};
  padding-top: 4px;
`;
