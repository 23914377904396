import React, { useEffect, useCallback } from 'react';

import { Router } from 'components/router';
import { useViewParams } from 'hooks/use-view-params.hook';
import { useAnalytics } from 'hooks/use-analytics.hook';
import { Skeleton } from 'components/skeleton';
import { useMiniappSdkEventHandler } from 'components/miniapp-sdk-provider/miniapp-sdk.hook';
import { useDispatch, useSelector } from 'react-redux';
import { getBuilding } from 'store/building/actions';
import { Building } from 'store/building/types';
import { InitHandlerPayload } from '@hqo/hqo-miniapp-client-sdk';
import { getBuildingTheme } from 'store/theme/actions';
import { BuildingTheme } from 'store/theme/types';
import { getCurrentUser } from 'store/user/actions';
import { User } from 'store/user/types';
import { useAppLoading } from 'components/app/use-app-loading.hook';
import { selectBuildingStatus } from 'store/building/selectors';
import { useBuildingTheme } from 'hooks/use-building-theme.hook';
import { useBuilding } from 'hooks/use-building.hook';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { ACTION_STATUSES, PROMISE_TIMEOUT } from 'shared/consts';
import { useGetTheme } from 'hooks/use-get-theme.hook';
import { useDefaultAppInstanceConfigUuid } from 'hooks/use-default-app-instance-config-uuid.hook';
import { useDefaultLocationId } from 'hooks/use-default-location-id.hook';
import { useSyncAccessTokenWithHostApp } from 'hooks/use-sync-access-token-with-hostapp.hook';
import useSyncKioskWithHostApp from 'store/kiosk/hooks/use-sync-kiosk-with-host-app';
import { useInitialRoute } from 'hooks/use-initial-route.hook';
import { selectInitialRoute } from 'store/routes/selectors';
import { updateInitialRoute } from 'store/routes/actions';
import 'mapbox-gl/dist/mapbox-gl.css';
import { setFeatureFlags, setLDUser } from 'store/featureFlags/actions';
import { useTimeout } from 'hooks/use-timeout.hook';
import { getAppInstanceConfigs } from 'store/app-instance-configs/actions';
import { selectAppInstanceConfigsStatus } from 'store/app-instance-configs/selectors';
import { OwnerTypesEnum } from 'store/app-instance-configs/enums';
import { LocaleEnum } from 'store/cart/types';

export const App: React.FC = withLDConsumer()(({ ldClient }): JSX.Element => {
  const { loading } = useAppLoading();
  const dispatch = useDispatch();
  useAnalytics();
  useBuildingTheme();
  useDefaultAppInstanceConfigUuid();
  useDefaultLocationId();
  const params = useViewParams();
  const buildingStatus = useSelector(selectBuildingStatus);
  const theme = useGetTheme();
  const building = useBuilding();
  const defaultRoute = useInitialRoute();
  const initialRoute = useSelector(selectInitialRoute);
  const appInstanceConfigsStatus = useSelector(selectAppInstanceConfigsStatus);

  const handleFeatureFlagChange = useCallback(() => {
    dispatch(setFeatureFlags(ldClient.allFlags()));
  }, [dispatch, ldClient]);

  useEffect(() => {
    ldClient.waitUntilReady().then(() => {
      dispatch(setFeatureFlags(ldClient.allFlags()));
      dispatch(setLDUser(ldClient.getUser()));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useTimeout(() => {
    if (appInstanceConfigsStatus !== ACTION_STATUSES.FULFILLED) {
      dispatch(
        getAppInstanceConfigs.request({
          lang: (params?.locale ?? LocaleEnum.en_US) as LocaleEnum,
          owners: [
            {
              uuid: building?.uuid,
              type: 'BUILDING' as OwnerTypesEnum,
            },
          ],
        }),
      );
    }
  }, PROMISE_TIMEOUT);

  useEffect(() => {
    ldClient.setStreaming(true);
    ldClient.on('change', handleFeatureFlagChange);

    return () => {
      ldClient.off('change', handleFeatureFlagChange);
    };
  }, [handleFeatureFlagChange, ldClient]);

  const buildingStatusFinished =
    buildingStatus === ACTION_STATUSES.FULFILLED || buildingStatus === ACTION_STATUSES.REJECTED;

  const hasBuilding = building || params.buildingUuid;

  useSyncAccessTokenWithHostApp();
  useMiniappSdkEventHandler(
    'initResponse',
    (data: InitHandlerPayload) => {
      if (!initialRoute) {
        dispatch(updateInitialRoute(data?.initial_route || defaultRoute));
      }
      dispatch(getCurrentUser.success({ user: data?.user as unknown as User }));
      dispatch(getBuilding.success(data?.building as unknown as Building));
      dispatch(getBuildingTheme.success(data?.theme as unknown as BuildingTheme));
    },
    [dispatch],
  );

  useSyncKioskWithHostApp();

  if (loading && theme && (buildingStatusFinished || !hasBuilding)) {
    return <Skeleton />;
  }

  return <Router />;
});
