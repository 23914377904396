import { useCallback, useEffect, useState } from 'react';
import { replace } from 'store/router/actions';
import qs from 'qs';
import { useDispatch } from 'react-redux';
import { useIsAppInstanceConfigNexudus } from 'hooks/use-is-app-instance-config-nexudus.hook';
import { useLocation } from 'react-router';
import { useSearchParams } from 'hooks/use-search-params.hook';
import { DEFAULT_TYPE_COMMA_DELIMITER, DEFAULT_TYPE_DELIMITER } from 'shared/consts';
import { useGetCurrentLocationResourcesTypesQuery } from 'store/resources/hooks';

interface UseFilterBarReturnValues {
  isCurrentConfigNexudus: boolean;
  isFilteredOptionsAreVisible: boolean;
  filterByTypeEncoded: string;
  dateRangeEncoded: string;
  startTimeEncoded: string;
  endTimeEncoded: string;
  onCancelClick: (value: string) => void;
  chips: string[];
}

export const getSelectedTypes = (
  filterByTypesString: string,
  resourcesTypes: Array<string> | undefined,
): Array<string> => {
  if (!resourcesTypes) return [];
  const selectedTypes: Array<string> = [];
  let currentType = '';
  for (let i = 0; i < filterByTypesString.length; i += 1) {
    if (filterByTypesString[i] !== ',') {
      currentType += filterByTypesString[i];
      if (i === filterByTypesString.length - 1 && resourcesTypes.includes(currentType)) {
        selectedTypes.push(currentType);
        currentType = '';
      }
    }
    if (filterByTypesString[i] === ',') {
      if (!resourcesTypes.includes(currentType)) {
        currentType += filterByTypesString[i];
      } else {
        selectedTypes.push(currentType);
        currentType = '';
      }
    }
  }

  return selectedTypes;
};

export const useFilterBar = (): UseFilterBarReturnValues => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [chips, setChips] = useState<Array<string>>([]);
  const {
    filterByType: filterByTypeEncoded,
    startDate: dateRangeEncoded,
    startTime: startTimeEncoded,
    endTime: endTimeEncoded,
    capacity,
    duration,
    filterByFloor,
  } = useSearchParams();

  const isCurrentConfigNexudus = useIsAppInstanceConfigNexudus();

  const isFilteredOptionsAreVisible =
    Boolean(filterByTypeEncoded) || Boolean(capacity) || Boolean(duration) || Boolean(filterByFloor);

  const onCancelClick = useCallback(
    (value: string) => {
      const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });

      const { filterByType, ...clearQueryParams } = queryParams;
      const filterChipsToString = chips
        ?.filter(chip => chip !== value)
        ?.map(chip => chip.replace(',', DEFAULT_TYPE_COMMA_DELIMITER))
        .join(',');

      const queryString = qs.stringify(
        chips.length === 1 && !!filterByType ? clearQueryParams : { ...queryParams, filterByType: filterChipsToString },
      );
      setChips(chips.filter(chip => chip !== value));
      dispatch(replace(`${location.pathname}?${queryString}`));
    },
    [dispatch, location, setChips, chips],
  );

  const { data: resourceTypes } = useGetCurrentLocationResourcesTypesQuery();

  useEffect(() => {
    if (typeof filterByTypeEncoded === 'string' && filterByTypeEncoded.length > 0) {
      const selectedTypes = getSelectedTypes(filterByTypeEncoded.replace(DEFAULT_TYPE_DELIMITER, ''), resourceTypes);
      setChips([...new Set(selectedTypes)]);
    } else {
      setChips([]);
    }
  }, [filterByTypeEncoded, resourceTypes, getSelectedTypes]);

  return {
    isFilteredOptionsAreVisible,
    isCurrentConfigNexudus,
    filterByTypeEncoded: filterByTypeEncoded as string,
    dateRangeEncoded: dateRangeEncoded as string,
    startTimeEncoded: startTimeEncoded as string,
    endTimeEncoded: endTimeEncoded as string,
    onCancelClick,
    chips,
  };
};
