import { setFeatureFlags, setLDUser } from './actions';
import { FeatureFlagReducer } from 'store/featureFlags/types';
import { createReducer, ActionType } from 'typesafe-actions';

const initialState: FeatureFlagReducer = {
  user: null,
  flags: null,
};

const setFeatureFlagsHandler = (
  state: FeatureFlagReducer,
  { payload }: ActionType<typeof setFeatureFlags>,
): FeatureFlagReducer => ({
  ...state,
  flags: payload,
});

const setLDUserHandler = (
  state: FeatureFlagReducer,
  { payload }: ActionType<typeof setLDUser>,
): FeatureFlagReducer => ({
  ...state,
  user: payload,
});

const featureFlagsReducer = createReducer(initialState)
  .handleAction(setFeatureFlags, setFeatureFlagsHandler)
  .handleAction(setLDUser, setLDUserHandler);

export default featureFlagsReducer;
