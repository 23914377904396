import React, { useMemo } from 'react';
import { CalendarFieldStyled, Label, CaretDownIcon, StyledCalendarIcon } from './styles';
import { useIntl } from 'react-intl';
import moment, { Moment } from 'moment';
import { faCalendar } from '@fortawesome/pro-regular-svg-icons/faCalendar';

interface DatePickerInputProps {
  value: Moment | null;
  setIsOpenedTrue?: VoidFunction;
  inputRef?: React.MutableRefObject<HTMLDivElement>;
  formattedDate: string;
  onClick?: VoidFunction;
  isDisabled?: boolean;
}

export const DatePickerInput = ({
  value,
  setIsOpenedTrue,
  inputRef,
  formattedDate,
  onClick,
  isDisabled,
}: DatePickerInputProps): JSX.Element => {
  const intl = useIntl();

  const getLabel = useMemo(() => {
    if (formattedDate) return formattedDate;

    return value ? moment(value).format('MMMM DD, YYYY') : intl.formatMessage({ id: 'selectDate' });
  }, [formattedDate, value]);

  return (
    <CalendarFieldStyled
      className="date-picker-select"
      data-testid="date-picker-select"
      isEmpty={!value}
      onClick={setIsOpenedTrue || onClick}
      ref={inputRef}
      isDisabled={isDisabled}
    >
      <StyledCalendarIcon icon={faCalendar} data-testid="nothing-available-now-icon" size="1x" />
      <Label>{getLabel}</Label>
      <CaretDownIcon variant="adminGreyDark" className="caret" />
    </CalendarFieldStyled>
  );
};
