import styled from 'styled-components';
import { Chip } from '@hqo/react-components-library/dist/atoms/chip';
import { DIMENSIONS } from '@hqo/react-components-library/dist/utils';

export const FilterBarContainer = styled.div<{ isVisible: boolean }>`
  display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
  gap: 13px;
  align-items: center;
  flex: 1;
  min-width: 50%;
`;

export const ChipContainer = styled.div`
  padding: 2px;
  overflow: scroll;
  display: flex;
  gap: 10px;
  width: 100%;
  min-height: fit-content;

  ::-webkit-scrollbar {
    width: 0;
    display: none;
  }
`;

export const StyledChipComponent = styled(Chip)`
  min-width: fit-content;
  white-space: nowrap;
`;

export const FilterSearchBarWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  width: 100%;
  height: 36px;

  .touch-search-bar-component {
    margin-bottom: 0;
  }

  @media only screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    flex-direction: column;
    height: initial;
    gap: 14px;
  }
`;

export const FilterSearchBarContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  width: 100%;
  max-width: 423px;
  margin-right: auto;
  justify-content: space-between;
  padding-right: 10px;

  @media only screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    padding-right: 0;
  }
`;
