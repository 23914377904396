import React, { useState, useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { SwitchV2 } from '@hqo/react-components-library/dist/molecules/switch-v2';
import { AvailableNowContainer, Label } from './styles';
import { useSearchParams } from 'hooks/use-search-params.hook';
import { searchParams } from 'components/time-selector/time-selector.interface';
import { pick } from 'utils/pickObjectProperty';
import { BOOLEAN_STRINGS, QUERY_PARAMS } from 'shared/consts';
import qs from 'qs';
import { replace } from 'store/router/actions';
import { useDispatch } from 'react-redux';

export const AvailableNow = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const queryParams = useSearchParams<searchParams>();

  const [availableNow, setAvailableNow] = useState<boolean>(null);

  useEffect(() => {
    setAvailableNow(queryParams.availableNow === BOOLEAN_STRINGS.TRUE);
  }, [queryParams]);

  const onCustomSwitchChange = useCallback(() => {
    setAvailableNow(!availableNow);
    const filteredParams = pick(
      queryParams,
      QUERY_PARAMS.filter(
        param =>
          param !== 'duration' &&
          param !== 'startTime' &&
          param !== 'endTime' &&
          param !== 'presetWindows' &&
          param !== 'startDate',
      ),
    );

    const updatedQueryParams = {
      ...filteredParams,
      availableNow: !availableNow,
    };
    const queryString = qs.stringify(updatedQueryParams);
    dispatch(replace(`${location.pathname}?${queryString}`));
  }, [availableNow, queryParams, dispatch]);

  return (
    <AvailableNowContainer>
      <SwitchV2 checked={availableNow} onChange={onCustomSwitchChange} hideIcons />
      <Label>{intl.formatMessage({ id: 'available_now' })}</Label>
    </AvailableNowContainer>
  );
};
