import { StyledSwipeModal } from './styles';
import React, { useMemo } from 'react';
import { ResourcesSwipeModalContent } from './components/resources-swipe-modal-content';
import { useSelector } from 'react-redux';
import { selectResources } from 'store/resources/selectors';

interface ResourcesSwipeModalProps {
  isLoadingState: boolean;
}

const MIN_POSITION_SWIPE_MODAL_FOR_MAP_VIEW = 167;
export const INITIAL_POSITION_SWIPE_MODAL_FOR_MAP_VIEW = 300;

export const ResourcesSwipeModal = ({ isLoadingState }: ResourcesSwipeModalProps): JSX.Element => {
  const resources = useSelector(selectResources);
  const positions = {
    initialPosition: INITIAL_POSITION_SWIPE_MODAL_FOR_MAP_VIEW,
    minPosition: MIN_POSITION_SWIPE_MODAL_FOR_MAP_VIEW,
  };
  const isSlideControlVisible = useMemo(
    () => Boolean(resources?.length) && !isLoadingState,
    [isLoadingState, resources?.length],
  );

  return (
    <StyledSwipeModal
      data-testid="modal"
      isSlideControlVisible={isSlideControlVisible}
      reverseAnimation={false}
      className="modal"
      noContentWrapperPadding
      content={<ResourcesSwipeModalContent isLoadingState={isLoadingState} />}
      positions={positions}
      hasBackground={false}
      withoutLeftIcon
      withLeftExitButton={false}
    />
  );
};
