import React, { useMemo } from 'react';
import { ResourceItem } from 'components/resources-list-item';
import { useSelector } from 'react-redux';
import { selectSelectedAppInstanceConfigsLocations } from 'store/app-instance-configs/selectors';
import { Resource } from 'store/resource/types';
import { useIntl } from 'react-intl';
import { SelectedBuildingsText, Container, Grid } from 'pages/resources/content/flex-content/styles';
import { useSearchParams } from 'hooks/use-search-params.hook';
import { searchParams } from 'shared/types/search-params';

export const DEFAULT_BUILDING_QUANTITY = 1;

export interface SectionedOffContentProps {
  resources: Array<Resource>;
  onResourceClick: (resourceUuid: string, locationUuid: string) => void;
}

export const SectionedOffContent = ({ resources, onResourceClick }: SectionedOffContentProps) => {
  const intl = useIntl();
  const selectedLocations = useSelector(selectSelectedAppInstanceConfigsLocations)?.flatMap(item => item.locationIds);
  const { locationId } = useSearchParams<searchParams>();

  const sectionedOffResources = useMemo(() => {
    const selectedLocationId = selectedLocations?.length > 0 ? selectedLocations : [locationId];
    const resourcesInTheSelectedBuilding = resources.filter(resource =>
      selectedLocationId.includes(resource.location.id),
    );
    const resourcesInTheSharedBuilding = resources.filter(
      resource => !selectedLocationId.includes(resource.location.id),
    );

    return {
      resourcesInTheSelectedBuilding,
      resourcesInTheSharedBuilding,
    };
  }, [locationId, resources, selectedLocations]);

  return (
    <Grid>
      {sectionedOffResources.resourcesInTheSelectedBuilding.length > 0 && (
        <>
          <SelectedBuildingsText>
            {intl.formatMessage(
              { id: 'resources_in_the_selected_buildings' },
              {
                numberOfBuildings: selectedLocations.length > 0 ? selectedLocations.length : DEFAULT_BUILDING_QUANTITY,
              },
            )}
          </SelectedBuildingsText>
          <Container>
            {sectionedOffResources.resourcesInTheSelectedBuilding?.map(resource => (
              <ResourceItem key={resource.uuid} resource={resource} onClick={onResourceClick} />
            ))}
          </Container>
        </>
      )}
      {sectionedOffResources.resourcesInTheSharedBuilding.length > 0 && (
        <>
          <SelectedBuildingsText>{intl.formatMessage({ id: 'shared_resources' })}</SelectedBuildingsText>
          <Container>
            {sectionedOffResources.resourcesInTheSharedBuilding?.map(resource => (
              <ResourceItem key={resource.uuid} resource={resource} onClick={onResourceClick} />
            ))}
          </Container>
        </>
      )}
    </Grid>
  );
};
