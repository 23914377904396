import { ITEM_TYPE, OwnerType, PATCH_STATUSES, PATCH_STATUSES_3DS, TRANSACTION_TYPES } from 'shared/consts/const';
import { Booking, BookingStatus } from 'store/bookings/types';
import { TRANSACTION_STATES } from 'shared/consts';
import { AddOnDto } from '../add-ons/types';
import { User } from 'store/user/types';

export interface GetTransactionsAPIResponse {
  data: GetTransactionsResponse;
}

export interface GetTransactionsResponse {
  transactions: TransactionDetails[];
}

export interface TransactionsState {
  transactions: TransactionDetails[];
  transaction: TransactionDetails;
  getTransaction: AsyncActionTypes;
  getTransactions: AsyncActionTypes;
  patchTransaction: PatchTransaction;
  loading: boolean;
  processed: boolean;
  refreshing: boolean;
  error?: SerializableError;
  getRedirectTransaction: AsyncActionTypes;
  redirectTransaction: RedirectTransactionDetails;
  checkIn: AsyncActionTypes;
  currentTransaction: TransactionDetails;
}

export interface PatchTransaction extends AsyncActionTypes {
  type: PATCH_STATUSES | PATCH_STATUSES_3DS;
}

export interface TransactionDetails {
  uuid: string;
  id: number;
  transaction_platform: string;
  payment_platform: string;
  total_price: number;
  total_charge: number;
  total_redeemed: number;
  total_discount?: number;
  total_tax: number;
  currency_type: string;
  type: TRANSACTION_TYPES;
  details: TransactionBookingDetails;
  content: ResourceBookingDetails;
  payment_method?: PaymentMethod;
}

export interface CheckInDetails {
  status?: string;
  transactionId?: number;
}

export interface PaymentMethod {
  type?: SupportedPaymentMethods;
  card_type?: string;
  last_four?: string;
  exp_month?: number;
  exp_year?: number;
  remaining_points?: number;
}

export enum SupportedPaymentMethods {
  INVOICE = 'INVOICE',
  CREDIT_CARD = 'CREDIT_CARD',
  APPLE_PAY = 'APPLE_PAY',
  CREDIT_POINTS = 'CREDIT_POINTS',
  AFTER_PAYMENT = 'AFTER_PAYMENT',
}

export enum AttendeeStatus {
  NONE = 'none',
  ACCEPTED = 'accepted',
  DECLINED = 'declined',
  CANCELLED = 'cancelled',
  TENTATIVE = 'tentative',
  CHECKED_IN = 'checked_in',
}

export enum AttendeeType {
  ORGANIZER = 'organizer',
  TENANT = 'tenant',
  VISITOR = 'visitor',
  GUEST = 'guest',
}

export interface AttendeeRO {
  id: number;
  uuid: string;
  email?: string;
  status?: AttendeeStatus;
  type?: AttendeeType;
  optional?: boolean;
  booking_id?: number;
  booking?: Booking;
  user_uuid?: string;
  user?: User;
  external_id?: string;
  created_at?: Date;
  updated_at?: Date;
  deleted_at?: Date;
}

export interface TransactionBookingDetails {
  name: string;
  id: string | number;
  uuid: string;
  resource: {
    id: string;
    name: string;
    features?: Array<ResourceFeature>;
    amenities_description?: string;
    timezone?: string;
  };
  resource_id: string;
  owner: {
    owner_type: OwnerType;
    owner_uuid: string;
  };
  location_name: string;
  user_id: number;
  start_at: string;
  end_at: string;
  created_at: string;
  status: BookingStatus;
  canceled_at?: string;
  cancellation_description: string;
  cancellation_window_end_minutes: number;
  user_info?: Array<FormResponse>;
  discount_applied: number;
  check_in: CheckInDetails;
  add_ons?: Array<AddOnDto>;
  attendees?: Array<AttendeeRO>;
}

export interface ResourceFeature {
  translations?: Record<string, ResourceFeatureTranslationsData>;
  name?: string | null;
  description?: string | null;
  default_type?: DefaultTypeEnum;
  icon?: string | null;
  cta_type?: CTATypeEnum;
  cta_value?: string | null;
  active?: boolean;
  created_at?: Date | null;
  deleted_at?: Date | null;
  updated_at?: Date | null;
  description_uuid?: string | null;
  id?: number | null;
  name_uuid?: string | null;
  resource_id?: number | null;
  uuid?: string | null;
  to_delete?: boolean | null;
}

export enum CTATypeEnum {
  URL = 'URL',
  EMAIL = 'EMAIL',
  PHONE = 'PHONE',
}

export enum DefaultTypeEnum {
  TV = 'TV',
  WIFI = 'WIFI',
  PHONE = 'PHONE',
}

interface ResourceFeatureTranslationsData {
  name: string;
  description: string;
}

export interface GetTransactionResponse {
  data: TransactionDetails;
}

interface ResourceBookingDetails {
  uuid: string;
  start_at: Date;
  end_at: Date;
  status: string;
  resource: {
    name: string;
    description: string;
    image_url: string;
  };
}

export interface PatchTransactionDto {
  transactionId: string;
  status?: PATCH_STATUSES;
  status_3ds?: PATCH_STATUSES_3DS;
  cartId?: string;
}

export interface GetUserTransactionPayload {
  itemType: ITEM_TYPE;
  itemId: string;
}

export interface RedirectTransactionDetails {
  token: string;
  succeeded: boolean;
  message: string;
  transaction_type: string;
  state: TRANSACTION_STATES;
}

export interface RedirectTransactionResponse {
  data: { transaction: RedirectTransactionDetails };
}

export interface UpdateCheckInResponse {
  status: string;
  transactionId?: number;
}

export interface UpdateCheckInAPIResponse {
  data: { check_in: { status: string } };
}
