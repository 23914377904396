import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { FloorPlanButton, Footer, FloorPlanButtonIcon } from './styles';
import { push } from 'store/router/actions';
import useHasFloorPlan from 'floorplan/pages/floorplan/hooks/use-has-floor-plan';

interface FloorPlanFooterParamsInterface {
  buttonTextId?: string;
  isMapViewEnabled?: boolean;
}

export const FloorPlanFooter = ({ buttonTextId, isMapViewEnabled }: FloorPlanFooterParamsInterface): JSX.Element => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const intl = useIntl();
  const hasFloorPlan = useHasFloorPlan();

  const onFloorPlanClick = useCallback(() => {
    dispatch(push(`/floorplan${search}`));
  }, []);

  return (
    hasFloorPlan && (
      <Footer isMapViewEnabled={isMapViewEnabled}>
        {buttonTextId && (
          <FloorPlanButton
            size="floorPlan"
            data-testid="floor-plan-button"
            text={intl.formatMessage({ id: buttonTextId })}
            variant="floorPlan"
            multiline={false}
            onClick={onFloorPlanClick}
            iconDirection="left"
            Icon={<FloorPlanButtonIcon variant="white" />}
          />
        )}
      </Footer>
    )
  );
};
