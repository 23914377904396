import { MIDNIGHT_HOURS_24H } from 'components/schedule-select/const';
import { FilterParams } from 'store/resources/types';

export const resolveTimeOfDay = (time: string) => {
  const [hour, minutes] = time && time.split(':');

  if (+hour === 24 && +minutes >= 0) {
    const resolvedTime = `00:${minutes}`;
    return resolvedTime;
  }
  return time;
};

export const getFilterParams = ({
  types,
  startDate,
  startTime,
  endTime: unvalidatedEndTime,
  duration,
  capacity,
  presetWindows,
  floor,
  startDates,
  availableNow,
}: FilterParams): FilterParams => {
  const formattedStartTime = startTime && resolveTimeOfDay(startTime);

  const { endTime, endDate } = calculateEndTimeAndDate({
    endTime: unvalidatedEndTime,
    startDate,
    startDates,
  });

  const params: FilterParams = {
    ...(types && { types }),
    ...(startDate && { startDate }),
    ...(formattedStartTime && { startTime: formattedStartTime }),
    ...(endTime && { endTime }),
    ...(duration && { duration }),
    ...(capacity && { capacity: +capacity }),
    ...(presetWindows && { presetWindows }),
    ...(floor && { floor }),
    ...(startDates && { dates: startDates }),
    ...(endDate && { endDate }),
    ...(availableNow && { availableNow }),
  };

  return params;
};

const calculateEndTimeAndDate = ({
  endTime,
  startDate,
  startDates,
}: {
  endTime: string | undefined;
  startDate: string;
  startDates: string;
}): {
  endTime: string;
  endDate?: string;
} => {
  const resolvedEndTime = endTime && resolveTimeOfDay(endTime);
  if (endTime !== MIDNIGHT_HOURS_24H || startDates) {
    return { endTime: resolvedEndTime };
  }
  const newDate = new Date(startDate);
  newDate.setDate(newDate.getDate() + 1);
  const endDate = newDate.toISOString().slice(0, 10);

  return { endTime: resolvedEndTime, endDate };
};
