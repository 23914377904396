import { useEffect, useState } from 'react';
import { useAppInstanceConfigFeature } from 'hooks/use-app-instance-config-feature.hook';
import { useIsSmallViewportWidth } from '@hqo/react-components-library/dist/viewport';

interface UseTopPositionReturnValues {
  isTopPosition: boolean;
}

export const useTopPosition = (): UseTopPositionReturnValues => {
  const { isMapViewEnabled } = useAppInstanceConfigFeature();
  const isMobileDevice = useIsSmallViewportWidth();
  const [isTopPosition, setIsTopPosition] = useState(null);

  useEffect(() => {
    const updateTopPosition = () => {
      const screenHeight = window.innerHeight;
      const contentElement = document.querySelector('.container-content');

      if (contentElement && isMapViewEnabled && isMobileDevice) {
        const top = contentElement.getBoundingClientRect().top + window.scrollY;

        setIsTopPosition(top >= screenHeight / 2);
      }
    };

    window.addEventListener('touchend', updateTopPosition);

    updateTopPosition();

    return () => {
      window.removeEventListener('touchend', updateTopPosition);
    };
  }, [isMapViewEnabled, isMobileDevice]);

  return { isTopPosition };
};
