import React from 'react';
import { NoResultsContainer, Title, Subtitle } from './styles';
import { useIntl } from 'react-intl';
import { Binoculars } from '@hqo/react-components-library/dist/icons';
import { useTopPosition } from './hooks/use-top-position.hook';

export const NoResults = (): JSX.Element => {
  const intl = useIntl();
  const { isTopPosition } = useTopPosition();

  return (
    <NoResultsContainer isTopPosition={isTopPosition}>
      <Binoculars size="xlg" variant="adminGreyDark" />
      <Title>{intl.formatMessage({ id: 'no_results' })}</Title>
      <Subtitle>{intl.formatMessage({ id: 'adjust_filters' })}</Subtitle>
    </NoResultsContainer>
  );
};
