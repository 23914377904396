import { OwnerTypesEnum } from 'store/app-instance-configs/enums';

export const VERTICAL_NAME = 'RESOURCE_BOOKING';
export const API_URL_LS_KEY = 'apiUrl';
export const APP_BRAND_LS_KEY = 'appBrand';
export const AUTH_TOKEN_LS_KEY = 'authToken';
export const BUILDING_UUID_LS_KEY = 'buildingUuid';
export const LOCALE_LS_KEY = 'locale';
export const APP_INSTANCE_CONFIG_UUID_LS_KEY = 'appInstanceConfigUuid';
export const SINGLE_APP_INSTANCE_CONFIG_LS_KEY = 'singleAppInstanceConfig';
export const SINGLE_LOCATION_CONFIG_LS_KEY = 'singleLocationConfig';
export const SDK_APP_UUID = '0d9949ac-f107-4410-a54a-8e4004cdcd48';

export const CREATE_BOOKING_STEPS = {
  CHECK_AVAILABILITY: 'CHECK_AVAILABILITY',
  ADDITIONAL_INFORMATION: 'ADDITIONAL_INFORMATION',
};

export enum OwnerType {
  BUILDING = 'building',
  BUILDING_COMPANY = 'building_company',
  COMPANY = 'company',
}

export const DEFAULT_OWNER_TYPE = OwnerTypesEnum.BUILDING;

export const ROOT_PATH = '/resource-booking/resources/*';
export const RESOURCES_PATH = '/resource-booking/resources';
export const RESOURCE_PATH = '/resource-booking/resource';
export const LANDING_PATH = '/';
export const REDIRECT_PATH = '/redirect';

export const ERROR_PATH = '/error';

export const DEFAULT_MODAL_WIDTH = '425px';
export const DEFAULT_MODAL_HEIGHT = '299px';
export const DEFAULT_MODAL_PADDINGS = ['48px', '32px', '48px', '32px'];
export const DEFAULT_MODAL_BOTTOM_MARGIN = '20px';
export const PLUS_LARGE_DIMENSION = '1400px';
export const EXTRA_LARGE_DIMENSION = '1600px';

export enum ACTION_STATUSES {
  PENDING = 'PENDING',
  FULFILLED = 'FULFILLED',
  REJECTED = 'REJECTED',
}

export enum PATCH_STATUSES {
  CANCELLED = 'cancelled',
}

export enum PATCH_STATUSES_3DS {
  SUCCEEDED = 'succeeded',
  FAILED = 'failed',
}

export const DEFAULT_LOCALE = 'en';
export const DEFAULT_TIMEZONE = 'America/New_York';

export enum FontLocation {
  TITLE = 'title',
  HEADING = 'heading',
  BODY = 'body',
}

export const LAUNCH_DARKLY_ANONYMOUS_EMAIL = 'anonymous@hqo.co';

export enum ITEM_TYPE {
  EVENT_POSTS = 'event_posts',
  RESOURCES_BOOKING = 'resources_booking',
  SERVICES_BOOKING = 'services_booking',
  ORDERING = 'ordering',
  MEMBERSHIP = 'membership',
}

export enum BOOKING_STATUSES {
  ACCEPTED = 'Accepted',
  PENDING = 'Pending',
  PAYMENT_PENDING = 'Payment_Pending',
}

export enum DEVELOPER_NAME {
  NEXUDUS = 'Nexudus',
  HQO = 'HqO',
}

export const CHECKOUT_BREAK_POINT = 872;

export const QUICK_CHECKOUT_BREAK_POINT = 542;

export const MAX_DISPLAYED_TYPES_COUNT = 10;

export const DEFAULT_TYPE_DELIMITER = '\\\\';

export const DEFAULT_TYPE_COMMA_DELIMITER = '\\\\,';

export const DEFAULT_BACKSLASH = '\\';

export const DEFAULT_COMMA_BACKSLASH = '\\,';

export const QUERY_PARAMS = [
  'appBrand',
  'locale',
  'buildingUuid',
  'startDate',
  'startTime',
  'endTime',
  'ownerType',
  'filterByType',
  'ownerUuid',
  'duration',
  'availableNow',
  'isExact',
  'locationId',
  'capacity',
  'presetWindows',
  'filterByFloor',
  'startDates',
  'step',
];

export enum TRANSACTION_TYPES {
  SERVICES_BOOKING = 'services_booking',
  RESOURCES_BOOKING = 'resources_booking',
  EVENT_POSTS = 'event_posts',
  ORDERING = 'ordering',
  MEMBERSHIP = 'membership',
}

export enum CalendarDateStatuses {
  AVAILABLE = 'available',
  ACTIVE = 'active',
  CURRENT = 'current',
  INACTIVE = 'inactive',
  UNAVAILABLE = 'unavailable',
  ADJACENT_MONTH = 'adjacentMonth',
}

export const LANDING_PAGE_MAX_WIDTH = 1200;
export const PAYMENT_CONTENT_WIDTH = '983px';

export const DATE_FORMAT = 'YYYY-MM-DD';

export enum TRACK_EVENT_TYPES {
  ACTION = 'action',
  IMPRESSION = 'impression',
}

export enum TRACK_EVENT_NAMES {
  RESOURCE_BOOKING_LANDING_IMPRESSION = 'resource_booking_landing_impression',
  LOCATION_CHANGE_DONE_CLICK = 'location_change_done_click',
  DATE_TIME_LANDING_CLICK = 'date_time_landing_click',
  AVAILABLE_NOW_RESOURCE_CLICK = 'available_now_resource_click',
  RESOURCE_TYPE_LANDING_CLICK = 'resource_type_landing_click',
  RESOURCE_DETAIL_IMPRESSION = 'resource_detail_impression',
  RESOURCE_LIST_IMPRESSION = 'resource_list_impression',
  FILTER_SHOW_RESULTS_CLICK = 'filter_show_results_click',
  RESOURCE_SCHEDULE_IMPRESSION = 'resource_schedule_impression',
  RESOURCE_BOOK_CLICK = 'resource_book_click',
  ADDITIONAL_INFO_IMPRESSION = 'additional_info_impression',
  QUICK_CHECKOUT_IMPRESSION = 'QUICK_CHECKOUT_IMPRESSION',
  FULL_CHECKOUT_IMPRESSION = 'FULL_CHECKOUT_IMPRESSION',
  CHECKOUT_CLICK = 'CHECKOUT_CLICK',
  RECEIPT_IMPRESSION = 'RECEIPT_IMPRESSION',
  RESOURCE_DETAIL_PAGE_DATE_INPUT_CLICK = 'rb_detail_page_date_input_click',
  RESOURCE_DETAIL_PAGE_START_TIME_CLICK = 'rb_detail_page_start_time_click',
  RESOURCE_DETAIL_PAGE_END_TIME_CLICK = 'rb_detail_page_end_time_click',
  RESOURCE_DETAIL_PAGE_TIMELINE_CLICK = 'rb_detail_page_timeline_click',
}

export enum TRACK_EVENT_SOURCES {
  FLOOR_PLAN = 'floor_plan',
  KIOSK = 'kiosk',
}

export enum DefaultFilterEnum {
  NONE = 'NONE',
  RESOURCE_TYPE = 'RESOURCE_TYPE',
  CAPACITY = 'CAPACITY',
}

export enum USER_AGENT {
  CHROME = 'chrome',
  SAFARI = 'safari',
}

export const DEFAULT_3DSECURE_VERSION = '2';
export const TOKENIZATION_METHOD = 'Spreedly';

export const REGISTERED_SIGN = '\u00ae';

export const EXPDATE_LENGTH = 5;
export const MIN_USERNAME_LENGTH = 1;
export const MIN_ZIP_CODE_LENGTH = 5;

export const SPREEDLY_CARD_REF = 'number';
export const SPREEDLY_CVV_REF = 'cvv';
export const SPREEDLY_CARD_ID = 'spreedly-credit-card';
export const SPREEDLY_CVV_ID = 'spreedly-cvv';

export enum CHECK_IN_STATUSES {
  OPEN = 'open',
  CONFIRMED = 'confirmed',
  CANCELED = 'canceled',
  AUTO_CANCELED = 'auto-canceled',
}

export const CHECK_IN_CLOSED_STATUSES = [
  CHECK_IN_STATUSES.CONFIRMED,
  CHECK_IN_STATUSES.CANCELED,
  CHECK_IN_STATUSES.AUTO_CANCELED,
];

export const DEFAULT_MARKED_OPTIONS = {
  gfm: true,
  breaks: true,
  pedantic: false,
  sanitize: false,
  smartLists: true,
  smartypants: false,
};

export const DEFAULT_LINK_ATTRIBUTES = {
  target: '_blank',
  rel: 'noreferrer',
};

export const BOOKING_DAYS_LIMIT = 15;

export const DATE_SELECT_MODAL_STEP = 0;
export const TIME_SELECT_MODAL_STEP = 1;

// the higher the number, the earlier the trigger for rendering the next item will be activated.
export const INFINITE_SCROLL_SENSITIVITY = 10;

export const TIME_RANGES_INTERVAL = 15;

export const MINUTES_IN_HOUR = 60;
export const MILLISECONDS_IN_SECOND = 1000;
export const SECONDS_IN_MINUTES = 60;

export enum RESOURCE_TRACKING_STATUSES {
  AVAILABLE = 'available',
  UNAVAILABLE = 'unavailable',
  ALL_DAY = 'allDay',
}

export const PROMISE_TIMEOUT = 5_000;

export const COMMA = ',';

export enum BOOLEAN_STRINGS {
  TRUE = 'true',
  FALSE = 'false',
}
