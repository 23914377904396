import styled from 'styled-components';

export const NoResultsContainer = styled.div<{ isTopPosition?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: ${({ isTopPosition }) => (isTopPosition ? 'flex-start' : 'center')};
  height: 100%;
  width: 100%;
  align-items: center;
  gap: 24px;
`;

export const Subtitle = styled.div`
  font: 400 14px/22px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.greys.adminGreyDark};
`;

export const Title = styled.div`
  font: 500 24px/28px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.fontColor};
`;
