import { createEpicMiddleware } from 'redux-observable';
import { RootAction } from 'store/actions';
import rootEpic from './epics';
import rootReducer, { RootState } from './reducer';
import { rootDependencies, RootDependencies } from 'store/dependencies';
import { createReduxEnhancer } from '@sentry/react';
import { configureStore } from '@reduxjs/toolkit';
import { sentryStateTransformer } from './utils/sentry-state-transformer.util';
import { setupListeners } from '@reduxjs/toolkit/query';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import helixApi from 'api/helix/resourceBooking';
import { tokenExpiryMiddleware } from 'store/middleware/tokenExpiryMiddleware';

const sentryReduxEnhancer = createReduxEnhancer({
  stateTransformer: sentryStateTransformer,
});

export const epicMiddleware = createEpicMiddleware<RootAction, RootAction, RootState, RootDependencies>({
  dependencies: rootDependencies,
});

const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== 'production' && {
    actionSanitizer: action => {
      if (action.type.startsWith('__rtkq/')) {
        return null;
      }
      return action;
    },
    name: 'resource-booking',
  },
  middleware: getDefaultMiddleWare =>
    getDefaultMiddleWare().concat(epicMiddleware).concat(helixApi.middleware, tokenExpiryMiddleware),
  enhancers: getDefaultEnhancers => getDefaultEnhancers().concat(sentryReduxEnhancer),
});

epicMiddleware.run(rootEpic);

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);

export default store;

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
