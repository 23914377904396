import React from 'react';
import { LandingPageContent } from './components/landing-page-content/landing-page-content';
import { useAppInstanceConfigFeature } from 'hooks/use-app-instance-config-feature.hook';
import ResourcesPage from '../resources/resources';
import { useNativeHeader } from './hooks/use-native-header.hook';
import { useFlags } from 'launchdarkly-react-client-sdk';

export const LandingPage = (): JSX.Element => {
  useNativeHeader();
  const { isMapViewEnabled } = useAppInstanceConfigFeature();
  const { resourceBookingSkipLandingPage } = useFlags();
  const shouldShowResourcesPage = isMapViewEnabled && resourceBookingSkipLandingPage;

  return shouldShowResourcesPage ? <ResourcesPage /> : <LandingPageContent />;
};
