import { ACTION_STATUSES, TRACK_EVENT_NAMES, TRACK_EVENT_TYPES } from 'shared/consts';
import {
  AFTER_PAYMENT_PAYMENT_TYPE_ID,
  CREDIT_PAYMENT_TYPE_ID,
  CREDIT_POINTS,
  INVOICE,
  INVOICE_PAYMENT_TYPE_ID,
} from 'shared/consts/payment-method-types';

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { resetComplete3DSCart, resetGetCartStatus, resetSubmitCartStatus, submitCart } from 'store/cart/actions';
import {
  selectCartDiscountSummary,
  selectComplete3DSCartStatus,
  selectSubmitCartError,
  selectSubmitCartStatus,
  shouldSubmit3DSecure,
} from 'store/cart/selectors';
import { selectTransaction, selectTransactionStatus } from 'store/transactions/selectors';
import { useDispatch, useSelector } from 'react-redux';

import { Order } from 'store/cart/types';
import { SavedPaymentMethod } from 'store/payment/types';
import { clearVisitors } from 'store/visitors-emails/actions';
import { formatCartItemsIds } from 'utils/formatTrackEventProps';
import { formatSummaryValues } from 'utils/formatSummaryValues';
import { getTransaction } from 'store/transactions/actions';
import qs from 'qs';
import { replace } from 'store/router/actions';
import { resetAddOnsState } from 'store/add-ons/actions';
import { searchParams } from 'pages/date-select-page/interface';
import { selectBuildingTimeZone } from 'store/building/selectors';
import { selectCurrentLocationId } from 'store/app-instance-configs/selectors';
import { selectResource } from 'store/resource/selectors';
import { track } from '@hqo/web-tracking';
import { useAddingVisitorsBlock } from 'components/adding-visitors-block/hooks/use-adding-visitors-block.hook';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useIsSmallViewportWidth } from '@hqo/react-components-library/dist/viewport';
import { useLocale } from 'hooks/use-locale.hook';
import { useLocation } from 'react-router-dom';
import { useMiniappSdk } from 'components/miniapp-sdk-provider/miniapp-sdk.hook';
import { useNativePaymentMethod } from 'components/payment/quick-checkout/components/use-native-payment-method.hook';
import { useSearchParams } from 'hooks/use-search-params.hook';
import { getResourceTimezone } from 'utils/getResourceTimezone';
import useKioskEnabled from 'store/kiosk/hooks/use-kiosk-enabled';
import BookingConfirmationModal from 'components/payment/quick-checkout/components/booking-confirmation-modal/booking-confirmation-modal';
import BookingKioskConfirmationModal from 'components/payment/quick-checkout/components/booking-kiosk-confirmation-modal/booking-kiosk-confirmation-modal';
import { useTrackingInfo } from 'hooks/use-tracking-information.hook';

interface Props {
  cart: Order;
  paymentMethods: SavedPaymentMethod[];
  setProcessPaymentFailed: () => void;
  isProcessPaymentFailed: boolean;
  currentPaymentMethodId: string;
  closeQuickCheckoutHandler: VoidFunction;
  openCheckout: VoidFunction;
}

export const Container = ({
  cart,
  paymentMethods,
  currentPaymentMethodId,
  setProcessPaymentFailed,
  isProcessPaymentFailed,
  closeQuickCheckoutHandler,
  openCheckout,
}: Props) => {
  const client = useMiniappSdk();
  const location = useLocation();
  const dispatch = useDispatch();
  const submitCartStatus = useSelector(selectSubmitCartStatus);
  const buildingTimeZone = useSelector(selectBuildingTimeZone);
  const contentRef = useRef(null);
  const { showMiniappPaymentsNavigation } = useFlags();
  const resource = useSelector(selectResource);
  const submit3DSecure = useSelector(shouldSubmit3DSecure);
  const cartError = useSelector(selectSubmitCartError);
  const locale = useLocale();
  const transaction = useSelector(selectTransaction);
  const transactionStatus = useSelector(selectTransactionStatus);
  const complete3DSCartStatus = useSelector(selectComplete3DSCartStatus);
  const { showResourceBookingResourceTermsAndConditions } = useFlags();
  const [isTermsChecked, setIsTermsChecked] = useState(false);

  const discountSummary = useSelector(selectCartDiscountSummary);
  const { total, currencyType } = formatSummaryValues(cart?.total_summary);
  const isMobileDevice = useIsSmallViewportWidth();
  const cartItemsIds = formatCartItemsIds(cart);
  const queryParams = useSearchParams<searchParams>();
  const { locationId: currentLocationId } = queryParams || {};
  const { pathname } = useLocation();
  const queryString = qs.stringify(queryParams);
  const locationId = useSelector(selectCurrentLocationId) || currentLocationId;
  const { isApplePayMethodSelected, setPendingNativePayment, pendingNativePayment, isNativePaymentError } =
    useNativePaymentMethod({ cart, locationId });
  const isKioskMode = useKioskEnabled();
  const { source: trackingSource } = useTrackingInfo();

  useEffect(() => {
    if (submitCartStatus === ACTION_STATUSES.FULFILLED) {
      dispatch(getTransaction.request(cart?.transaction_uuid));
    }
  }, [cart?.transaction_uuid, dispatch, submitCartStatus]);

  const isPaymentMethodsAvailable = !!paymentMethods?.length;
  const isFreeCart = !cart.total_summary.total;

  const paymentMethod = useMemo<SavedPaymentMethod>(() => {
    const creditPointsMethod = paymentMethods?.find(method => method?.payment_method_type === CREDIT_POINTS);
    if (creditPointsMethod && creditPointsMethod.points_balance >= cart.total_summary.total) {
      return creditPointsMethod;
    }

    if (paymentMethods?.length === 1 && paymentMethods[0].payment_method_type === INVOICE) {
      return paymentMethods[0];
    }

    return paymentMethods?.find(({ id }) => id?.toString() === currentPaymentMethodId);
  }, [cart?.total_summary?.total, currentPaymentMethodId, paymentMethods]);

  const resourceTimezone = useMemo(() => {
    return getResourceTimezone(resource, buildingTimeZone);
  }, [resource, buildingTimeZone]);

  const isVisiblePaymentMethodBlock = !isFreeCart || (!isFreeCart && paymentMethod?.payment_method_type === INVOICE);

  const isTermsVisible = useMemo(
    () => showResourceBookingResourceTermsAndConditions && !!resource.terms_and_conditions,
    [showResourceBookingResourceTermsAndConditions, resource],
  );

  const hasInvoiceWithoutCreditCard = useMemo(() => {
    const hasCreditCardOption = cart.config && cart.config.payment_gateway_id != null;
    const hasOnlyInvoiceOption =
      paymentMethods.length === 1 && !!paymentMethods.find(method => method.payment_method_type === INVOICE);
    return hasCreditCardOption && hasOnlyInvoiceOption;
  }, [cart.config, paymentMethods]);

  const { formattedAttendees, error: visitorError } = useAddingVisitorsBlock();

  const isAllowedVisitorsExist = Boolean(resource?.allowed_visitors);

  useEffect(() => {
    if (submitCartStatus === ACTION_STATUSES.REJECTED) {
      setProcessPaymentFailed();
    }
  }, [setProcessPaymentFailed, submitCartStatus]);

  useEffect(() => {
    const hasSuccessfullyPurchasedWithCreditCard =
      submitCartStatus === ACTION_STATUSES.FULFILLED && transactionStatus === ACTION_STATUSES.FULFILLED;

    if (
      hasSuccessfullyPurchasedWithCreditCard &&
      (!submit3DSecure ||
        paymentMethod?.id.toString() === CREDIT_PAYMENT_TYPE_ID ||
        paymentMethod?.id.toString() === INVOICE_PAYMENT_TYPE_ID ||
        paymentMethod?.id.toString() === AFTER_PAYMENT_PAYMENT_TYPE_ID)
    ) {
      dispatch(resetAddOnsState());
      /**
       * When in kiosk mode, we do not display the receipt
       */
      if (!isKioskMode) {
        dispatch(replace(`${pathname.replace('quick-checkout', 'receipt')}?${queryString}`));
      } else {
        dispatch(replace(`${pathname.replace('quick-checkout', '')}?${queryString}`));
      }

      dispatch(resetSubmitCartStatus());
      dispatch(clearVisitors());
      dispatch(resetGetCartStatus());
    }
  }, [
    showMiniappPaymentsNavigation,
    submitCartStatus,
    dispatch,
    paymentMethod,
    isMobileDevice,
    cart,
    transactionStatus,
    transaction?.details.id,
    isKioskMode,
    submit3DSecure,
  ]);

  const trackCheckoutClick = useCallback(() => {
    track(
      TRACK_EVENT_NAMES.CHECKOUT_CLICK,
      {
        type: TRACK_EVENT_TYPES.ACTION,
        payment_method: paymentMethod?.payment_method_type,
        cart_total: total,
        currency_code: currencyType,
        promo_code_applied: !!discountSummary,
        cart_type: cart.type,
        items: cartItemsIds,
        adapter: cart.config?.config?.adapter,
        source: trackingSource,
      },
      { sendToHqoTracking: true },
    );
  }, []);

  const onCTAClick = useCallback(() => {
    if (!isPaymentMethodsAvailable && !isFreeCart) {
      openCheckout();
    }
    if (isFreeCart) {
      trackCheckoutClick();
      dispatch(
        submitCart.request({
          cart_id: cart.id as string,
          locationId,
          ...(Boolean(formattedAttendees?.length) && { attendees: formattedAttendees }),
        }),
      );
    } else if (isApplePayMethodSelected) {
      setPendingNativePayment(true);
      client?.payments.showNativePayment(
        null,
        {
          currency: cart.total_summary.currency_type,
          total: cart.total_summary.total / 100,
          tax: cart.total_summary.tax_summary.taxes_total / 100,
          authorization_total: cart.total_summary.total / 100,
        },
        cart.config.payment_gateway_id,
      );
    } else if (isPaymentMethodsAvailable) {
      trackCheckoutClick();
      const paymentInfo = {
        cart_id: cart.id as string,
        payment_method_id: paymentMethod?.id.toString(),
        locationId,
      };
      if (complete3DSCartStatus === ACTION_STATUSES.REJECTED) {
        dispatch(resetComplete3DSCart());
      }

      const searchString = qs.stringify({ ...queryParams, cartId: cart?.id, paymentId: paymentInfo.payment_method_id });

      const payload = {
        ...paymentInfo,
        browser_info: window.Spreedly?.ThreeDS?.serialize('01'),
        attempt_3dsecure: true,
        three_ds_version: '2',
        callback_url: `${window.location.origin}/redirect?${searchString}`,
        redirect_url: `${window.location.origin}/redirect?${searchString}`,
      };

      dispatch(
        submitCart.request(
          submit3DSecure &&
            paymentMethod?.id.toString() !== CREDIT_PAYMENT_TYPE_ID &&
            paymentMethod?.id.toString() !== INVOICE_PAYMENT_TYPE_ID &&
            paymentMethod?.id.toString() !== AFTER_PAYMENT_PAYMENT_TYPE_ID
            ? payload
            : paymentInfo,
        ),
      );
    }
  }, [
    isFreeCart,
    isPaymentMethodsAvailable,
    trackCheckoutClick,
    dispatch,
    cart?.id,
    locationId,
    paymentMethod?.id,
    complete3DSCartStatus,
    submit3DSecure,
    location?.search,
    isApplePayMethodSelected,
    formattedAttendees,
  ]);

  useEffect(() => {
    track(
      TRACK_EVENT_NAMES.QUICK_CHECKOUT_IMPRESSION,
      {
        type: TRACK_EVENT_TYPES.IMPRESSION,
        cart_total: total,
        currency_code: currencyType,
        promo_code_applied: !!discountSummary,
        cart_type: cart.type,
        items: cartItemsIds,
        adapter: cart.config?.config?.adapter,
        source: trackingSource,
      },
      { sendToHqoTracking: true },
    );
  }, []);

  const onEditClick = useCallback(() => {
    openCheckout();
  }, []);

  const checkoutDisabled = useMemo(() => {
    return (isTermsVisible && !isTermsChecked) || (Boolean(formattedAttendees?.length) && Boolean(visitorError));
  }, [isTermsVisible, isTermsChecked, formattedAttendees, visitorError]);

  if (isKioskMode) {
    return (
      <BookingKioskConfirmationModal
        ref={contentRef}
        cart={cart}
        checkoutDisabled={checkoutDisabled}
        closeQuickCheckoutHandler={closeQuickCheckoutHandler}
        onCTAClick={onCTAClick}
        isTermsVisible={isTermsVisible}
        isTermsChecked={isTermsChecked}
        onIsTermsChecked={setIsTermsChecked}
        submitCartStatus={submitCartStatus}
        resourceTimezone={resourceTimezone}
        locale={locale}
        cartError={cartError}
      />
    );
  }

  return (
    <BookingConfirmationModal
      ref={contentRef}
      checkoutDisabled={checkoutDisabled}
      closeQuickCheckoutHandler={closeQuickCheckoutHandler}
      isProcessPaymentFailed={isProcessPaymentFailed}
      onCTAClick={onCTAClick}
      onEditClick={onEditClick}
      cart={cart}
      resourceTimezone={resourceTimezone}
      isVisiblePaymentMethodBlock={isVisiblePaymentMethodBlock}
      locale={locale}
      isAllowedVisitorsExist={isAllowedVisitorsExist}
      isNativePaymentError={isNativePaymentError}
      pendingNativePayment={pendingNativePayment}
      isTermsVisible={isTermsVisible}
      isTermsChecked={isTermsChecked}
      cartError={cartError}
      currentPaymentMethodId={paymentMethod?.id.toString()}
      visitorError={visitorError}
      hasInvoiceWithoutCreditCard={hasInvoiceWithoutCreditCard}
      paymentMethod={paymentMethod}
      isFreeCart={isFreeCart}
      isPaymentMethodsAvailable={isPaymentMethodsAvailable}
      onIsTermsChecked={setIsTermsChecked}
      submitCartStatus={submitCartStatus}
    />
  );
};
