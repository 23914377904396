import { Resource } from 'store/resource/types';

interface GetResourceBuildingOptions {
  isResourceBookingPortfolioBased?: boolean;
  isEssensysAdapter?: boolean;
}

export const getResourceBuilding = (resource: Resource, options?: GetResourceBuildingOptions) => {
  const { isResourceBookingPortfolioBased, isEssensysAdapter } = options || {};
  const buildingUuid = resource.building_uuid || '';
  const nativeBuildingName = buildingUuid
    ? resource.buildings.find(building => building.uuid === buildingUuid)?.name
    : resource.buildings?.[0]?.name;
  const city = resource.location?.city || resource.location?.name;
  const buildingName = isEssensysAdapter ? resource.location?.name : nativeBuildingName;

  if (isResourceBookingPortfolioBased) {
    return `${city} (${buildingName})`;
  }
  return '';
};
