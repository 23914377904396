import { faCalendar } from '@fortawesome/pro-light-svg-icons';
import { StyledCalendarIcon } from 'components/headers/styles';
import { TitleContainer } from '../styles';
import { Text } from 'pages/resources/components/resources-swipe-modal/components/styles';
import { useHeader } from 'pages/resources/components/hooks/use-header.hook';
import React from 'react';
import { useIntl } from 'react-intl';

export const MobileTitle = (): JSX.Element => {
  const { onMyBookingsClick } = useHeader();
  const intl = useIntl();

  return (
    <TitleContainer data-testid="mobile-header">
      <Text>{intl.formatMessage({ id: 'reserveSpace' })}</Text>
      <StyledCalendarIcon onClick={onMyBookingsClick} icon={faCalendar} data-testid="my-bookings-button" />
    </TitleContainer>
  );
};
