import { Grid, ResourcesListContainer } from '../styles';
import React, { useRef } from 'react';
import { Content } from '../content';
import { Skeleton } from 'components/skeleton';
import { ResourcesCounterBlock } from './resources-counter-block';
import { useSelector } from 'react-redux';
import { selectResources } from 'store/resources/selectors';
import { useAppInstanceConfigFeature } from 'hooks/use-app-instance-config-feature.hook';
import { FloorPlanFooter } from 'components/floor-plan-footer';

interface ResourcesListProps {
  isLoadingState: boolean;
  shouldShowFloorPlanFooter: boolean;
}

export const ResourcesList = ({ isLoadingState, shouldShowFloorPlanFooter }: ResourcesListProps): JSX.Element => {
  const resources = useSelector(selectResources);
  const { isCompactUiEnabled, isMapViewEnabled } = useAppInstanceConfigFeature();
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  return (
    <ResourcesListContainer isLoadingState={isLoadingState} isMapViewEnabled={isMapViewEnabled}>
      <ResourcesCounterBlock />
      {isLoadingState ? (
        <Skeleton isCompactUiEnabled={isCompactUiEnabled} />
      ) : (
        <Grid
          id="scrollable-resources-list"
          ref={scrollContainerRef}
          isNoResources={resources?.length === 0 && !isLoadingState}
          isCompactUiEnabled={isCompactUiEnabled}
        >
          <Content scrollContainerRef={scrollContainerRef} resources={resources} />
        </Grid>
      )}
      {shouldShowFloorPlanFooter && <FloorPlanFooter buttonTextId="floorPlan" isMapViewEnabled={isMapViewEnabled} />}
    </ResourcesListContainer>
  );
};
